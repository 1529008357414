import type { LinkDOMProps } from '@react-types/shared';
import { clsx } from 'clsx/lite';
import { type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';
import {
  type AriaButtonProps,
  type HoverProps,
  mergeProps,
  useButton,
  useFocusRing,
  useHover,
} from 'react-aria';
import { ButtonContext, useContextProps } from 'react-aria-components';
import type { NavigateOptions } from 'react-router';
import { isNonNullish, omit } from 'remeda';

import { useTheme } from '../../contexts/theme.js';
import { useMapMediaProp } from '../../hooks/use-mapmedia-prop.js';
import { Loading } from '../../icons/loading/index.js';
import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import { vars } from '../../theme-contract.css.js';
import type { DOMAttributes } from '../../types.js';
import type { MediaValue } from '../../types/index.js';
import { getRecipeStyleProps } from '../../utilities/get-recipe-style-props.js';
import {
  type ThemeVariant,
  getThemeVariant,
} from '../../utilities/internal.js';
import { Box } from '../box/index.js';
import {
  type ButtonVariants,
  buttonRecipe,
  hideOutlineOnFocusStyle,
} from './button.css.js';
import { type Size, sizes } from './size.js';

export type ButtonProps = Omit<ButtonVariants, 'color'> &
  DOMAttributes &
  Omit<LinkDOMProps, 'routerOptions'> &
  AriaButtonProps &
  HoverProps & {
    color?: ThemeVariant<ButtonVariants['color']>;
    css?: RainbowSprinkles;
    isPending?: boolean;
    continuePropagation?: boolean;
    hideOutlineOnFocus?: boolean;
    size?: Size | MediaValue<Size>;
    title?: string;
    textColor?: ThemeVariant<NonNullable<RainbowSprinkles['color']>>;
    routerOptions?: NavigateOptions;
  };

function Button(
  props: PropsWithChildren<ButtonProps>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  [props, ref] = useContextProps(props, ref, ButtonContext);

  const {
    children,
    className,
    css,
    color = 'red',
    isDisabled,
    isPending,
    textColor,
    inverted = false,
    hideOutlineOnFocus,
    elementType,
    kind = 'primary',
    size = 'small',
    inline,
    ...restProps
  } = props;

  const theme = useTheme();

  if (isNonNullish(restProps.onClick) && isNonNullish(restProps.onPress)) {
    throw new Error(
      'The web.accomplice Button accepts either native events (onClick, onKeyDown, onPointerDown, etc.) or RAC events (onPress, etc.), not both.',
    );
  }

  const ElementType = elementType ?? (restProps?.href ? 'a' : 'button');

  const { buttonProps, isPressed } = useButton(
    {
      ...restProps,
      elementType: ElementType,
      isDisabled,
      preventFocusOnPress: true,
    },
    ref,
  );
  const { focusProps, isFocused, isFocusVisible } = useFocusRing(restProps);
  const { isHovered, hoverProps } = useHover({
    ...restProps,
    isDisabled: isDisabled || isPending,
  });

  const sizeCSS = useMapMediaProp(
    size,
    (value: keyof typeof sizes) => sizes[value],
  );

  const rs = getRecipeStyleProps(
    buttonRecipe,
    {
      ...omit(restProps, ['onPress']),
      inline,
      inverted,
      color: getThemeVariant(color, theme),
      kind,
    },
    { ...sizeCSS, ...css },
  );

  const classNames = clsx(
    rs.className,
    className,
    hideOutlineOnFocus && hideOutlineOnFocusStyle,
  );

  const styles = {
    ...rs.style,
    color: getThemeVariant(textColor, theme) as string,
  };

  return (
    <ElementType
      {...mergeProps(buttonProps, focusProps, hoverProps)}
      className={classNames}
      data-disabled={isDisabled || undefined}
      data-focus-visible={isFocusVisible || undefined}
      data-focused={isFocused || undefined}
      data-hovered={isHovered || undefined}
      data-pressed={isPressed || undefined}
      href={restProps?.href}
      ref={ref}
      role={restProps?.href ? 'link' : 'button'}
      style={styles}
    >
      {children}
      {isPending ?
        <Box
          asChild
          height="100%"
          left="0"
          pointerEvents="none"
          position="absolute"
          top="0"
          width="100%"
          zIndex={vars.zIndex[1]}
        >
          <Loading color={vars.color.gray300} />
        </Box>
      : null}
    </ElementType>
  );
}

export const _Button = forwardRef(Button);
export { _Button as Button };
